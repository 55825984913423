import { get, set } from 'lodash'
import { useState } from 'react'
import { InlineSummary } from 'admin/components/InlineEdit'
import { useUpdateCurrentClientSettings } from 'admin/hooks/use-users'
import { Grid } from 'components/Grid'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'

function isValidUrl(string) {
  try {
    const url = new URL(string)
    return url.protocol === 'https:'
  } catch {
    return false
  }
}

export const PanelAPI = () => {
  const { user } = useSession()
  const [error, setError] = useState('')
  const { mutateAsync: update } = useUpdateCurrentClientSettings()
  const clientId = get(user, 'client.id', '')
  const settings = get(user, 'client.settings', {
    webhook: '',
  })

  return (
    <Panel title="API" className="max-w-panel">
      <Grid gap={24}>
        <Grid.Item xs={24} className="pb-2">
          <InlineSummary
            type="text"
            name="Webhook URL"
            value={settings.webhook || 'https://'}
            save={(value) => {
              if (value === '') {
                // Let it be stored as empty string
              } else if (
                !value ||
                !value.toString().match(/^https:\/\/.+/) ||
                !isValidUrl(value)
              ) {
                setError('Invalid URL')
                return Promise.reject()
              }
              setError('')
              const updatedSettings = {
                ...settings,
                webhook: value as string,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
          {error && <p className="text-red-100 text-sm">{error}</p>}
        </Grid.Item>
      </Grid>
    </Panel>
  )
}
